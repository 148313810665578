<template>
  <section class="fixed top-0 right-0 z-[1000] m-4">
    <div
      class="flex flex-col items-center gap-4 overflow-hidden rounded-xl bg-white bg-opacity-70 p-3 shadow-lg backdrop-blur-sm backdrop-filter transition-all duration-300"
      :class="{
        'h-[340px] w-[360px] pb-3': expanded && activeLayer.id === 'solutions',
        'h-[90px] w-auto': expanded && activeLayer.id !== 'solutions',
        'h-[40px] w-full min-w-[210px] max-w-max ': !expanded,
      }"
    >
      <header
        class="flex w-full items-center justify-between gap-2 text-black transition-all duration-300"
      >
        <div
          class="flex items-center gap-1.5 font-bold capitalize"
          :class="expanded ? 'text-black' : 'text-blue'"
        >
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.46692 10.2072C0.46692 10.2072 6.59179 13.604 7.83719 14.2944C7.97098 14.3688 8.10719 14.4 8.23611 14.4C8.38043 14.4 8.51503 14.3608 8.62854 14.2984C9.88206 13.608 16.0515 10.208 16.0515 10.208C16.251 10.0976 16.3637 9.8936 16.3637 9.6832C16.3637 9.2304 15.8658 8.936 15.4588 9.16C15.4588 9.16 9.74098 12.3264 8.5353 12.976C8.36422 13.068 8.1753 13.1048 7.94989 12.9808C6.61287 12.2384 1.06287 9.1608 1.06287 9.1608C0.655839 8.9352 0.157191 9.2288 0.157191 9.6832C0.157191 9.8928 0.268272 10.0968 0.46692 10.2072ZM0.46692 7.7168C0.46692 7.7168 6.59179 11.1136 7.83719 11.804C7.97098 11.8784 8.10719 11.9096 8.23611 11.9096C8.38043 11.9096 8.51503 11.8704 8.62854 11.808C9.88206 11.1176 16.0515 7.7176 16.0515 7.7176C16.251 7.6072 16.3637 7.4032 16.3637 7.1928C16.3637 6.74 15.8658 6.4456 15.4588 6.6696C15.4588 6.6696 9.74098 9.836 8.5353 10.4856C8.36422 10.5776 8.1753 10.6144 7.94989 10.4904C6.61287 9.748 1.06287 6.6704 1.06287 6.6704C0.655839 6.4448 0.157191 6.7384 0.157191 7.1928C0.157191 7.4024 0.268272 7.6064 0.46692 7.7168ZM8.79476 0.1528C8.5953 0.0504 8.41287 0 8.23368 0C8.05287 0 7.87449 0.052 7.68638 0.1528L0.483137 4.1568C0.259353 4.3032 0.147461 4.512 0.147461 4.7152C0.147461 4.92 0.260164 5.1192 0.483137 5.2464L7.68638 9.2512C7.86557 9.3576 8.04881 9.4136 8.23611 9.4136C8.41692 9.4136 8.6026 9.3616 8.79476 9.2512L15.998 5.2464C16.2372 5.1136 16.3637 4.9096 16.3637 4.7024C16.3637 4.5024 16.2461 4.3 15.998 4.1568L8.79476 0.1528ZM1.9807 4.7016L8.24098 1.2224L14.5004 4.7016L8.24098 8.1816L1.9807 4.7016Z"
              fill="#343434"
            />
          </svg>
          <p class="text-xs" v-html="menuLabel" />
        </div>
        <div class="h-2 w-3 cursor-pointer">
          <div @click="closeMenu" v-if="expanded">
            <svg
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.20856 1.0305L1.31042 3.8905C1.23643 3.9635 1.13863 4 1.04133 4C0.836603 4 0.661265 3.838 0.661265 3.6255C0.661265 3.529 0.698259 3.433 0.772245 3.3595C1.91231 2.23483 3.0353 1.09106 4.20856 -3.10115e-07C8.30897 4.04574 3.72574 -0.506708 7.64488 3.3595C7.71887 3.433 7.75586 3.529 7.75586 3.6255C7.75586 3.838 7.58052 4 7.37579 4C7.27849 4 7.18069 3.9635 7.1067 3.8905L4.20856 1.0305Z"
                fill="#868686"
              />
            </svg>
          </div>
          <div @click="openMenu" v-else>
            <svg
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.20856 1.0305L1.31042 3.8905C1.23643 3.9635 1.13863 4 1.04133 4C0.836603 4 0.661265 3.838 0.661265 3.6255C0.661265 3.529 0.698259 3.433 0.772245 3.3595C1.91231 2.23483 3.0353 1.09106 4.20856 -3.10115e-07C8.30897 4.04574 3.72574 -0.506708 7.64488 3.3595C7.71887 3.433 7.75586 3.529 7.75586 3.6255C7.75586 3.838 7.58052 4 7.37579 4C7.27849 4 7.18069 3.9635 7.1067 3.8905L4.20856 1.0305Z"
                fill="#868686"
              />
            </svg>
          </div>
        </div>
      </header>
      <section v-if="expanded" class="w-full">
        <ul
          class="m-0 flex list-none items-center gap-2 p-0 text-xs transition-all duration-300"
        >
          <li
            v-html="layer.translation"
            v-for="layer in TranslatedLayers"
            :key="layer.id"
            @click="setLayerAndRedirect(layer)"
            class="cursor-pointer overflow-hidden rounded-md bg-blue py-1 px-2 capitalize transition-all duration-300"
            :class="
              isActiveLayer(layer.id)
                ? 'bg-opacity-100 text-grey-3/70'
                : 'bg-opacity-5 hover:bg-opacity-20'
            "
          />
        </ul>
      </section>
      <section
        v-if="expanded && activeLayer.id === 'solutions'"
        class="flex flex-col gap-2 overflow-y-auto text-xs text-blue transition-all duration-300"
        :class="expanded ? 'h-full w-full' : 'h-0 w-0'"
      >
        <div
          v-if="activeLayer.id === 'solutions'"
          class="flex justify-start gap-12"
        >
          <div class="flex flex-col gap-4">
            <div class="justify-left flex items-center gap-1 text-grey-2">
              <p
                class="text-xs font-bold capitalize"
                v-html="getTranslations('solution portfolio')"
              ></p>
            </div>
            <ul
              class="m-0 flex list-none flex-col items-start justify-center gap-2 overflow-hidden p-0"
            >
              <li
                v-for="portfolio in portfolios.filter(
                  (item) => item.id != 7 && item.id != 8
                )"
                :key="portfolio.id"
                class="relative flex items-center justify-center gap-2 pl-3"
              >
                <input
                  type="radio"
                  :id="portfolio.id"
                  :name="portfolio.name"
                  :value="portfolio"
                  v-model="selectedPortfolio"
                  @change="onSelectPortfolio(selectedPortfolio)"
                  class="radio-button absolute top-0 left-0 z-10 h-3.5 w-3.5 cursor-pointer opacity-0"
                />
                <span
                  class="checkmark absolute top-0 left-0 h-3.5 w-3.5 rounded-full border border-blue bg-white"
                ></span>
                <label
                  :for="portfolio.id"
                  v-html="portfolio.name"
                  class="ml-2"
                ></label>
              </li>
              <li
                v-for="portfolio in portfolios.filter(
                  (item) => item.id == 7 || item.id == 8
                )"
                :key="portfolio.id"
                class="relative flex items-center justify-center gap-2 pl-3"
              >
                <input
                  type="radio"
                  :id="portfolio.id"
                  :name="portfolio.name"
                  :value="portfolio"
                  v-model="selectedPortfolio"
                  @change="onSelectPortfolio(selectedPortfolio)"
                  class="radio-button absolute top-0 left-0 z-10 h-3.5 w-3.5 cursor-pointer opacity-0"
                />
                <span
                  class="checkmark absolute top-0 left-0 h-3.5 w-3.5 rounded-full border bg-white"
                  :class="{
                    'innovation border-[#FF8B00]': portfolio.id === 7,
                    'water-saving border-[#0060FF]': portfolio.id === 8,
                  }"
                ></span>
                <label
                  :for="portfolio.id"
                  v-html="portfolio.name"
                  class="ml-2"
                  :class="{
                    'text-[#FF8B00]': portfolio.id === 7,
                    'text-[#0060FF]': portfolio.id === 8,
                  }"
                ></label>
              </li>
            </ul>
          </div>
          <div class="flex flex-col gap-4">
            <div class="justify-left flex items-center gap-1 text-black">
              <p
                v-html="getTranslations('value drivers')"
                class="text-xs font-bold capitalize"
              ></p>
            </div>
            <ul
              class="m-0 flex list-none flex-col items-start justify-center gap-2 overflow-hidden p-0"
            >
              <li
                v-for="valueDriver in valueDrivers"
                :key="valueDriver.id"
                class="flex items-center justify-center gap-2"
              >
                <input
                  type="checkbox"
                  :id="valueDriver.id + '-driver'"
                  :value="valueDriver"
                  v-model="selectedValueDrivers"
                  @change="updateValueDrivers()"
                  class="checkbox-button rounded-sm border-purple bg-transparent accent-blue"
                />
                <label :for="valueDriver.id + '-driver'" class="ml-2">
                  {{ valueDriver.name }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script lang="js" src="./FiltersMenu"></script>
<style lang="scss" src="./FiltersMenu.scss" scoped></style>
