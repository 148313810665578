<template>
  <div class="home flex h-screen flex-col w-full">
    <img class="fixed top-0 left-0 sm:top-0 sm:left-18 w-32 sm:w-56 m-4" src="/images/logo.png" alt="logo" />
    <header class="flex flex-col items-center justify-center mt-6 mb-1">
      <h1 class="text-3xl font-bold">Diversey GPS</h1>
    </header>
    <main v-if="apiStatus === 'idle'" class="h-5/6 w-screen p-5 pt-0 overflow-auto md:overflow-hidden">
      <LanguageButton class="fixed top-0 right-0 sm:top-0 sm:right-18" :toggleLanguageModal="toggleLanguageModal"/>
    <LanguageModal  v-if="show_language_modal" :toggleLanguageModal="toggleLanguageModal" class="fixed h-screen w-screen -top-4 -left-4" />
    <h2 class="text-center text-2xl text-black first-letter:uppercase">
      {{ toTitleCase(getTranslations('global products and services for the food and beverage industry')) }}
    </h2>
    <h3 class="text-center text-xl text-black mt-2 first-letter:uppercase">
      {{ toTitleCase(getTranslations('select an industry to get started:')) }}
    </h3>
    <!-- Desktop view -->
    <ul class="hidden h-[90%] items-center justify-evenly gap-2 lg:flex m-0 p-0 py-2.5 list-none">
      <li v-for="category in categories" :key="category.id"
        class="category-cards-container h-[90%] w-1/5 cursor-pointer transition-all duration-500 hover:w-1/4">
        <router-link :to="'/en/' + category.slug">
          <CategoryCard v-bind="category" :isScreen="false" />
        </router-link>
      </li>
    </ul>
    <!-- Mobile View -->
      <ul class="grid grid-cols-2 items-stretch justify-center gap-4 lg:hidden  m-0 p-0 list-none overflox-y-scroll">
      <li v-for="category in categories" :key="category.id" class="min-h-[150px] w-full cursor-pointer">
        <router-link :to="'/en/' + category.slug">
          <CategoryCard v-bind="category" :isScreen="false" />
        </router-link>
      </li>
    </ul>


    </main>
    <section v-if="apiStatus === 'busy'" class="flex flex-grow items-center justify-center">
      <LoaderDots :isLoading="apiStatus === 'busy'" />
    </section>
    <section v-if="apiStatus === 'error'">
      <h1 class="text-center">{{ errorMessage }}</h1>
    </section>
    <section v-if="apiStatus === 'failed'">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">500</h1>
          <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl capitalize">
            {{ getTranslations('internal server error') }}
          </p>
          <p class="mb-4 text-lg font-light normal-case">
              {{ getTranslations('we apologize for the inconvenience. our team is currently addressing the issue. please check back later. thank you for your patience and understanding') }}
          </p>
        </div>
      </div>
    </section>
    <footer class="flex items-center justify-between px-9 py-3.5 bg-dark-blue w-full bottom-0 absolute">
      <a class="text-white text-xs" href="https://www.solenis.com/en/privacy-statement/">https://www.solenis.com/en/privacy-statement/</a>
      <div class="flex items-stretch gap-3.5 h-5">
        <a href="https://www.youtube.com/channel/UCchfOscfbvTFd90dMs3-Kmw">
          <img class="h-full" src="/images/icons/youtube-icon.svg" alt="youtube icon" />
        </a>
        <a href="https://www.linkedin.com/company/solenis">
          <img class="h-full" src="/images/icons/linkedin-icon.svg" alt="linkedin icon" />
        </a>
        <a href="https://twitter.com/TeamSolenis">
          <img class="h-full" src="/images/icons/x-icon.svg" alt="x icon" />
        </a>
        <a href="https://www.facebook.com/Solenis-285432878481907/">
          <img class="h-full" src="/images/icons/facebook-icon.svg" alt="facebook icon" />
        </a>
      </div>
    </footer>
  </div>
</template>

<script lang="js" src="./HomeScreen.js"></script>
<style lang="scss" src="./HomeScreen.scss" scoped></style>
