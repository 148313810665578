import { mapGetters, mapActions } from "vuex";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "FiltersMenu",
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    XMarkIcon,
  },
  props: {},
  data() {
    return {
      expanded: true,
      selectedValueDrivers: [],
      selectedPortfolio: null,
    };
  },
  computed: {
    ...mapGetters(["layers", "activeLayer"]),
    ...mapGetters("portfolios", ["portfolios", "activePortfolio"]),
    ...mapGetters("valueDrivers", ["valueDrivers", "activeValueDrivers"]),
    ...mapGetters("staticContent", ["getTranslations"]),
    menuLabel() {
      this.selectedPortfolio = this.activePortfolio;
      let menuLabel = this.activeLayer.name;
      if (this.activeLayer.id === "solutions") {
        menuLabel = this.getTranslations('solutions') + ` - ${this.activePortfolio?.name}`;
      } else {
        menuLabel = this.getTranslations(this.activeLayer.name.replace(/-/g, " ").replace(/&nbsp;/g, " "));
      }

      return menuLabel;
    },
    TranslatedLayers() {
      return this.layers.map((layer) => {
        const name = layer.name.replace(/-/g, " ").replace(/&nbsp;/g, " ");
        return {
          ...layer,
          translation: this.getTranslations(name),
        };
      });
    },
  },
  methods: {
    ...mapActions(["setActiveLayer"]),
    ...mapActions("portfolios", ["setActivePortfolio"]),
    ...mapActions("valueDrivers", ["setActiveValueDrivers"]),
    isActiveValueDriver(ValueDriver) {
      if (this.activeValueDrivers == null) {
        return false;
      }

      return (
        this.activeValueDrivers.some((k) => k.id === ValueDriver.id).length > 0
      );
    },
    isActiveLayer(layer) {
      return layer === this.activeLayer.id;
    },

    onSelectPortfolio (portfolio) {
      this.setActivePortfolio({ activePortfolio: portfolio });
      this.$emit('filterChange');
    },

    updateValueDrivers() {
      this.setActiveValueDrivers({ valueDrivers: this.selectedValueDrivers });
    },

    openMenu() {
      if (!this.expanded) this.expanded = true;
    },
    closeMenu() {
      if (this.expanded) this.expanded = false;
    },
    setLayerAndRedirect(layer) {
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: layer.id,
        },
      });
    },
  },
};
