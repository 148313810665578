<template>
  <LoaderDots :isLoading="isLoading" />
  <BaseDialog :isOpen="isOpen" @close="close" @runAfterLeave="runAfterLeave">
    <template #default>
      <div v-if="isNavigating"
        class="absolute top-3 left-0 flex h-[2px] w-full items-center justify-center overflow-hidden px-4">
        <LoaderSearch :isLoading="isNavigating" />
      </div>

      <div class="overflow-y-overlay solution-modal flex  w-[95vw] h-[90vh] md:h-[60vh] md:w-[70vw] flex-col gap-4 overflow-scroll pb-8">
        <header
          class="sticky top-0 z-[999] flex items-center justify-between rounded-t-xl bg-[#F1F0FF] px-4 py-3 text-xl md:px-8">
          <h1 class="block text-lg font-extrabold md:hidden md:text-xl">
            {{ hotspotTitle }}
          </h1>
          <h1 class="hidden text-lg font-extrabold md:block md:text-xl">
            {{ hotspot.title }}
          </h1>

          <div class="flex items-center justify-between text-xl md:justify-end py-0.5 md:gap-12 gap-4">
            <div class="flex gap-8">
              <button @click="prevProcess" class="hover:opacity-60">
                <img src="/images/icons/icon-arrow-simple-rg-blue.svg" alt="back" class="h-4 w-4 rotate-180" />
              </button>
              <button @click="nextProcess" class="hover:opacity-60">
                <img src="/images/icons/icon-arrow-simple-rg-blue.svg" alt="forward" class="h-4 w-4" />
              </button>
            </div>

            <button @click="close" class="font-bold">
              <XMarkIcon
                class="h-5 w-5 text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698] md:h-[28px] md:w-[28px]" />
            </button>
          </div>
        </header>

        <main class="flex w-full flex-col md:pt-5 md:flex-row gap-8 md:gap-0">

          <aside v-if="hasImages" class="z-50 mx-5 md:ml-8 md:w-[35%]">
            <img v-for="img in images" :src="img" :alt="img" :key="img"
              class="w-full max-h-56 object-cover sticky top-[6.3rem] rounded-lg" />
          </aside>

          <section class="w-full px-6 md:w-[65%]">
            <div class="flex h-full w-full flex-col gap-4 pb-2 pr-2 md:pr-8">

              <div v-html="hotspot.description"></div>
              
              <div class="flex flex-col gap-2 pt-8">
                <p class="italic capitalize" v-if="relatedSolutions.length > 0">
                  {{ getTranslations('related solutions') }}
                </p>
                <div class="flex w-full flex-wrap items-center gap-4">
                  <RouterLink v-for="(related, index) in relatedSolutions" :key="index" :to="related.link">
                    <SquareArrowButton :title="related.title" type="solution" />
                  </RouterLink>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </template>
  </BaseDialog>
</template>

<script src="./ProcessHotspotModal"></script>
<style scoped lang="scss" src="./Style.scss"></style>
