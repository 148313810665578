<template>
  <div
    class="group z-30 flex h-[120px] w-[120px] flex-col justify-between gap-1 rounded-lg p-2 transition-all duration-300 ease-in-out hover:opacity-60"
    :class="{
      'border border-[#0f0698]': type === 'solution',
      'bg-[#E3551F]': type === 'content' && contentType === 'blog',
      'bg-[#760698]': type === 'content' && contentType === 'document',
      'bg-[#0E0698]': type === 'content' && contentType === 'webinar',
      'bg-[#069817]': type === 'content' && contentType === 'training',
    }"
  >
    <div class="flex flex-col items-start justify-start">
      <p
        v-if="type === 'content'"
        class="max-w-[90%] break-words text-[10px] uppercase text-white"
      >
        {{ contentType }}
      </p>
      <p
        v-html="title"
        class="max-w-[90%] break-words text-left text-sm font-extrabold text-blue"
        :class="{
          'text-white': type === 'content',
        }"
      ></p>
    </div>
    <img
      v-if="type === 'solution'"
      src="/images/icons/icon-arrow-simple-rg-blue.svg"
      alt=""
      class="h-3 w-3 self-end fill-black"
    />
    <img
      v-if="type === 'content'"
      src="/images/icons/arrow-right-white.svg"
      alt=""
      class="h-3 w-3 self-end fill-white"
    />
  </div>
</template>

<script lang="js" src="./SquareArrowButton.js"></script>
<style lang="scss" src="./SquareArrowButton.scss" scoped></style>
