import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "InnovationButton",
  data() {
    return {
      active: false,
    };
  },
  methods: {
    ...mapActions(["setActiveLayer"]),
    ...mapActions("portfolios", ["setActivePortfolio"]),
    ...mapActions("valueDrivers", ["setActiveValueDrivers"]),
    setLayerAndRedirect(layer) {
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: layer.id,
        },
      });
    },
    openInnovations() {
      this.setLayerAndRedirect({ id: "solutions" });
      let innovations = { id: 7, slug: "innovation", name: "Innovation" };
      this.setActivePortfolio({ activePortfolio: innovations });

      this.active = !this.active;

      if (!this.active) {
        let all = { id: 1, slug: "all", name: "All" };
        this.setActivePortfolio({ activePortfolio: all });
      }

      if ((this.activePortfolio?.name ?? "").toLowerCase() != "innovation") {
        event.target.checked = false;
      }
    },
    toggleActive(event) {
      if (this.active) {
        event.target.checked;
      }
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
    ...mapState("portfolios", ["activePortfolio"]),
  },
  watch: {
    activePortfolio(new_val) {
      if ((new_val?.name ?? "").toLowerCase() !== "innovation") {
        this.active = false;
      }else{
        this.active = true;
      }
    },
  },
};