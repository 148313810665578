<div class="grid-screen overflow-hidden" ref="viewport user-select-none">
	<div v-if="!has_placeholder" id="grid-image-test" class="grid-image fixed top-0 left-0 w-32 h-32 p-0 m-0 bg-center bg-no-repeat bg-contain" draggable="false" :style="getImageStyle()">
		<div class="relative w-full h-full">
			<PegComponent v-if="original_image != null" v-for="hotspot in visible_hotspots" v-bind="hotspot" :viewport_width="current_image_width" :viewport_height="current_image_height" :zoom="zoom_level"></PegComponent>
		</div>
	</div>
	<div v-if="loading && !show_notice && !has_placeholder" class="fixed flex flex-col h-screen w-screen items-center justify-center bg-white">
		<LoaderDots :isLoading="loading" />
		<p class="capitalize">
			{{ getTranslations('loading...') }}
		</p>
	</div>
	<BackInfoButtons v-if="original_image != null" :title="roomTitle" @open-info-modal="showNotice" @help="help" class="fixed bottom-0 left-0" />
	<LanguageButton v-if="activeLanguage" class="fixed bottom-11 left-0 sm:bottom-0 sm:left-[11rem]" :toggleLanguageModal="toggleLanguageModal"/>

	<GridControls v-if="original_image != null" @zoom-in="updateZoom(-zoom_step)" @zoom-out="updateZoom(zoom_step)" @open-search-modal="show_search_modal = true" :zoom="zoom_level" :can_zoom_in="zoom_level != max_zoom" :can_zoom_out="zoom_level != min_zoom"></GridControls>
	<FiltersMenu v-if="original_image" @filterChange="onFilterChange" />


	<div v-if="has_placeholder" class="fixed z-[3000] flex h-screen w-screen items-center justify-center" :style="{backgroundImage: 'url(' + original_image + ')'}">
		<div class="text-4xl font-bold tracking-wide">
			{{ getTranslations('coming soon') }}
		</div>
	</div>

	<NoticeComponent v-if="original_image" v-model:isVisible="show_notice" :title="roomTitle" :roomDescription="currentSector?.description"></NoticeComponent>
	<LanguageModal v-if="show_language_modal" :toggleLanguageModal="toggleLanguageModal" />
	<SearchModal v-if="original_image" v-model:isVisible="show_search_modal" :hotspots="visible_hotspots" />
	<div v-if="original_image" class="fixed bottom-0 right-0 z-50 m-4 rounded-xl bg-white bg-opacity-70 p-1 text-lg shadow-lg backdrop-blur-sm backdrop-filter">
		<ValueDriverButtons @openModal="openDriverModal" />
	</div>
	<ValueDriverModal v-model:isVisible="driverModalVisible" :valueDriverId="valueDriverId" />
	<div class="absolute right-0 bottom-[9%] mr-4 flex gap-3 flex-col">
		<InnovationButton />
		<WaterSavingButton />
	</div>
	<InfoCard v-if="original_image && visible_hotspots.length > 0" :title="currentSector.title" :content="currentSector.description" />
	<OffscreenHotspotsIndicator :offscreen-top="offscreen_top" :offscreen-bottom="offscreen_bottom" :offscreen-left="offscreen_left" :offscreen-right="offscreen_right" />
	<router-view v-if="original_image" :key="$route.path"></router-view>
</div>